import { SHOW_SUCCESS_MESSAGE, setMessageProps } from "actions";
import { put, takeLatest } from "redux-saga/effects";

function* handleShowSuccessMessage(action) {
    try {
        yield put(setMessageProps({ successMessage: action.payload })); // Set success message

        // Auto-hide after 3 seconds
        yield new Promise((resolve) => setTimeout(resolve, 3000));

        yield put(setMessageProps({ successMessage: "" })); // Clear message
    } catch (error) {
        console.error("Error handling success message:", error);
    }
}

export default function* messageSaga() {
    yield takeLatest(SHOW_SUCCESS_MESSAGE, handleShowSuccessMessage);
}

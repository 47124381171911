import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RateReviewIcon from "@mui/icons-material/RateReview";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { IconButton, Menu, MenuItem, TableCell, Tooltip } from "@mui/material";
import React, { memo, useState } from "react";

const menuOptions = [
    { role: ["admin", "clientuser"], label: "Edit", icon: <ModeEditOutlineIcon />, action: "onEditClick" },
    { role: ["admin", "clientuser"], label: "Cancel", icon: <CancelIcon />, action: "onCancelClick" },
    { role: ["admin"], label: "Delete", icon: <DeleteIcon />, action: "onDeleteClick" },
    { role: ["admin"], label: "Activity Log", icon: <InfoIcon />, action: "onAuditLogClick" },
    { role: ["admin", "clientuser"], label: "Rate Review", icon: <RateReviewIcon />, action: "onRateReviewClick" },
    // { role: ["staff"], label: "Available", icon: <CheckCircleIcon />, action: "onAvailableClick" },
    // { role: ["staff"], label: "Unavailable", icon: <RemoveCircleIcon />, action: "onUnavailableClick" },
];

const ActionMenu = memo(({ creedRequest, LoginUserRole, onCancelClick, onDeleteClick, onAuditLogClick, onRateReviewClick, onEditClick, onAvailableClick, onUnAvailableClick }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const actionMap = { onCancelClick, onDeleteClick, onAuditLogClick, onRateReviewClick, onEditClick, onAvailableClick, onUnAvailableClick };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuAction = (action) => {
        if (actionMap[action]) {
            actionMap[action](creedRequest);
        }
        handleClose();
    };

    const filteredOptions = menuOptions.filter(option => option.role.includes(LoginUserRole));

    return (
        <TableCell>

            <>
                {
                    LoginUserRole !== "staff" &&
                    <IconButton aria-label="more options" onClick={handleClick}>
                        <Tooltip title="Actions">
                            <MoreVertIcon />
                        </Tooltip>
                    </IconButton>
                }
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    {filteredOptions.map(({ label, icon, action }) => (
                        <MenuItem key={label} onClick={() => handleMenuAction(action)}>
                            {icon} {label}
                        </MenuItem>
                    ))}
                </Menu>
                {
                    LoginUserRole === "staff" && creedRequest.showAvalButton && <IconButton aria-label="Available">
                        <Tooltip title="Mark Available">

                            <CheckCircleIcon color='success'
                                onClick={() => onAvailableClick(creedRequest)}
                            />
                        </Tooltip>
                    </IconButton>
                }


                {
                    LoginUserRole === "staff" && creedRequest.showAvalButton && <IconButton aria-label="Unavailable">
                        <Tooltip title="Mark Unavailable">

                            <RemoveCircleIcon color='error'
                                onClick={() => onUnAvailableClick(creedRequest)}
                            />
                        </Tooltip>
                    </IconButton>
                }
            </>

        </TableCell>
    );
});

export default ActionMenu;

import { createAction, payloadAction, simpleAction } from "utils/stateUtils";

export const CREED_REQUEST_SET_PROPS = "CREED_REQUEST_SET_PROPS";

export const GET_CREED_REQUESTS = "GET_CREED_REQUESTS";
export const ADD_CREED_REQUEST = "ADD_CREED_REQUEST";
export const ADD_USER_TO_CREED_REQUEST = "ADD_USER_TO_CREED_REQUEST";
export const APPROVE_CREED_REQUEST = "APPROVE_CREED_REQUEST";
export const REASSIGN_CREED_REQUEST = "REASSIGN_CREED_REQUEST";
export const SITE_CONTACT_SUGGESTION_BYPROJECT = "SITE_CONTACT_SUGGESTION_BYPROJECT";
export const REMOVE_CREED_REQUEST = "REMOVE_CREED_REQUEST";
export const CANCEL_CREED_REQUEST = "CANCEL_CREED_REQUEST";

export const REFRESH_CREED_REQUESTS = "REFRESH_CREED_REQUESTS";
export const CREED_REQUEST_DETAILS = "CREED_REQUEST_DETAILS";
export const ONUSERUNAVAILABLE = "ON_USER_UNAVAILABLE";
export const ONUSERAVAILABLE = "ON_USER_AVAILABLE";
export const CREED_RECOMMENDATION = "CREED_RECOMMENDATION";
export const CREED_FEEDBACKS = "CREED_FEEDBACKS";
export const GET_JOBDETAILS_BYNOTIFY_ID = "GET_JOBDETAILS_BYNOTIFY_ID";

export const setCreedRequestProps = payloadAction(CREED_REQUEST_SET_PROPS);

export const getCreedRequests = payloadAction(GET_CREED_REQUESTS);
export const addCreedRequest = payloadAction(ADD_CREED_REQUEST);
export const addUserToCreedRequest = payloadAction(ADD_USER_TO_CREED_REQUEST);
export const approveCreedRequest = payloadAction(APPROVE_CREED_REQUEST);
export const reassignCreedRequest = payloadAction(REASSIGN_CREED_REQUEST);
export const siteContactSuggestionByProject = payloadAction(SITE_CONTACT_SUGGESTION_BYPROJECT);
export const getCreedFeedBacks = payloadAction(CREED_FEEDBACKS);
export const getJobDetailsByNotifyId = payloadAction(GET_JOBDETAILS_BYNOTIFY_ID);
export const removeCreedRequest = payloadAction(REMOVE_CREED_REQUEST);
export const cancelCreedRequest = payloadAction(CANCEL_CREED_REQUEST);

export const onUserAvailable = payloadAction(ONUSERAVAILABLE);
export const onUserUnAvailable = payloadAction(ONUSERUNAVAILABLE);
export const getCreedRecommendation = payloadAction(CREED_RECOMMENDATION);
export const refreshVirtualCard = simpleAction(REFRESH_CREED_REQUESTS);
export const showCreedRequestDetails = (creedRequest) =>
  createAction(CREED_REQUEST_DETAILS, { creedRequest });



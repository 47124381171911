/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CreedFeedBack from "../model/CreedFeedBack";
import CreedRequest from '../model/CreedRequest';
import RecommendationList from "../model/RecomendationList";
import UserCreedsNotify from "../model/UserCreedsNotify";
import APIEndPoints from "./ApiEndpoints";

/**
* CardsController service.
* @module api/CreedRequestControllerApi
* @version 1.0
*/
export default class CreedRequestControllerApi {

    /**
    * Constructs a new CreedRequestControllerApi. 
    * @alias module:api/CreedRequestControllerApi
    * @class
    * @param {module:ApiClient} [apiCreedRequest] Optional API creedRequest implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiCreedRequest) {
        this.apiCreedRequest = apiCreedRequest || ApiClient.instance;
        this.endPoints = new APIEndPoints();
    }




    /**
     * getCreedRequest
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/CreedRequest>} and HTTP response
     */
    getCreedRequestsUsingGETWithHttpInfo(requestType, searchString) {
        let postBody = null;

        let pathParams = {
        };
        let queryParams = {
            search: searchString,
            sortBy: 'StartDate:DESC',
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = [CreedRequest];
        return this.apiCreedRequest.callApi(
            `${this.endPoints.creedRequest.GetAll.URI}&requestType=${requestType}`,
            this.endPoints.creedRequest.GetAll.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getCreedRequest
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CreedRequest>}
     */
    getCreedRequestsUsingGET(requestType, searchString) {
        return this.getCreedRequestsUsingGETWithHttpInfo(requestType, searchString)
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }


    /**
     * getAddCreedRequest
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/CreedRequest>} and HTTP response
     */
    addCreedRequestWithHttpInfo(body) {
        let postBody = body;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = CreedRequest;
        return this.apiCreedRequest.callApi(
            this.endPoints.creedRequest.Post.URI,
            this.endPoints.creedRequest.Post.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getAddCreedRequest
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CreedRequest>}
     */
    addCreedRequest(body) {
        return this.addCreedRequestWithHttpInfo(body)
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }

    addUserToCreedRequestWithHttpInfo(body) {
        let postBody = body;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = CreedRequest;
        return this.apiCreedRequest.callApi(
            this.endPoints.creedRequest.AddUserToCreedRequest.URI,
            this.endPoints.creedRequest.AddUserToCreedRequest.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getAddCreedRequest
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CreedRequest>}
     */
    addUserToCreedRequest(body) {
        return this.addUserToCreedRequestWithHttpInfo(body)
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }


    approveCreedRequestWithHttpInfo(body) {
        let postBody = body;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = CreedRequest;
        return this.apiCreedRequest.callApi(
            this.endPoints.creedRequest.Approve.URI + "/" + body,
            this.endPoints.creedRequest.Approve.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getAddCreedRequest
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CreedRequest>}
     */
    approveCreedRequest(body) {
        return this.approveCreedRequestWithHttpInfo(body)
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }

    reassignCreedRequestWithHttpInfo(body) {
        let postBody = body;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = CreedRequest;
        return this.apiCreedRequest.callApi(
            this.endPoints.creedRequest.Reassign.URI + "/" + body,
            this.endPoints.creedRequest.Reassign.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getAddCreedRequest
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CreedRequest>}
     */
    reassignCreedRequest(body) {
        return this.reassignCreedRequestWithHttpInfo(body)
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }
    siteContactSuggestionByProjectWithHttpInfo(body) {
        let postBody = body;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = [CreedRequest];
        return this.apiCreedRequest.callApi(
            this.endPoints.creedRequest.SiteContactSuggestionByProject.URI + "/" + body,
            this.endPoints.creedRequest.SiteContactSuggestionByProject.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getAddCreedRequest
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CreedRequest>}
     */
    siteContactSuggestionByProjectRequest(body) {
        return this.siteContactSuggestionByProjectWithHttpInfo(body)
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }
    getCreedFeedBacksWithHttpInfo(body) {
        let postBody = body;

        let pathParams = {
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = [CreedFeedBack];
        return this.apiCreedRequest.callApi(
            this.endPoints.creedRequest.CreedFeedBacks.URI + "/" + body,
            this.endPoints.creedRequest.CreedFeedBacks.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getAddCreedRequest
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CreedRequest>}
     */
    getCreedFeedBacks(body) {
        return this.getCreedFeedBacksWithHttpInfo(body)
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }


    /**
 * getCreedRequest
 * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/CreedRequest>} and HTTP response
 */
    removeCreedRequestWithHttpInfo(id) {
        let postBody = null;

        let pathParams = {
            id: id
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = CreedRequest;
        return this.apiCreedRequest.callApi(
            this.endPoints.creedRequest.Delete.URI,
            this.endPoints.creedRequest.Delete.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getCreedRequest
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CreedRequest>}
     */
    removeCreedRequest(id) {
        return this.removeCreedRequestWithHttpInfo(id)
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }

    availableUserCreedRequestWithHttpInfo(id) {
        let postBody = null;

        let pathParams = {
            id: id
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = CreedRequest;
        return this.apiCreedRequest.callApi(
            this.endPoints.creedRequest.Available.URI,
            this.endPoints.creedRequest.Available.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getCreedRequest
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CreedRequest>}
     */
    availableUserCreedRequest(id) {
        return this.availableUserCreedRequestWithHttpInfo(id)
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }

    unavailableUserCreedRequestWithHttpInfo(id) {
        let postBody = null;

        let pathParams = {
            id: id
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = CreedRequest;
        return this.apiCreedRequest.callApi(
            this.endPoints.creedRequest.Unavailable.URI,
            this.endPoints.creedRequest.Unavailable.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getCreedRequest
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CreedRequest>}
     */
    unavailableUserCreedRequest(id) {
        return this.unavailableUserCreedRequestWithHttpInfo(id)
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }

    cancelCreedRequestWithHttpInfo(id) {
        let postBody = null;

        let pathParams = {
            id: id
        };
        let queryParams = {
        };
        let headerParams = {
        };
        let formParams = {
        };

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ['*/*'];
        let returnType = CreedRequest;
        return this.apiCreedRequest.callApi(
            this.endPoints.creedRequest.Cancel.URI,
            this.endPoints.creedRequest.Cancel.Type,
            pathParams, queryParams, headerParams, formParams, postBody,
            authNames, contentTypes, accepts, returnType, null
        );
    }

    /**
     * getCreedRequest
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/CreedRequest>}
     */
    cancelCreedRequest(id) {
        return this.cancelCreedRequestWithHttpInfo(id)
            .then(function (response_and_data) {
                return response_and_data.data;
            });
    }


    getCreedRequestByIdUsingGET(body) {
        return this.getCreedRequestByIdUsingGETWithHttpInfo(body).then(function (
            response_and_data
        ) {
            return response_and_data.data;
        });
    }

    getCreedRequestByIdUsingGETWithHttpInfo(id) {

        let postBody = {};

        let pathParams = {
            id: id
        };
        let queryParams = {};
        let headerParams = {};
        let formParams = {};

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ["*/*"];
        let returnType = CreedRequest;
        return this.apiCreedRequest.callApi(
            this.endPoints.creedRequest.GetById.URI,
            this.endPoints.creedRequest.GetById.Type,
            pathParams,
            queryParams,
            headerParams,
            formParams,
            postBody,
            authNames,
            contentTypes,
            accepts,
            returnType,
            null
        );
    }

    getJobDetailsByNotifyIdUsingGET(body) {
        return this.getjobDetailsByNotifyIdUsingGETWithHttpInfo(body).then(function (
            response_and_data
        ) {
            return response_and_data.data;
        });
    }

    getjobDetailsByNotifyIdUsingGETWithHttpInfo(id) {

        let postBody = {};

        let pathParams = {
            id: id
        };
        let queryParams = {};
        let headerParams = {};
        let formParams = {};

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ["*/*"];
        let returnType = UserCreedsNotify;
        return this.apiCreedRequest.callApi(
            this.endPoints.creedRequest.JobDetailsByNotifyId.URI,
            this.endPoints.creedRequest.JobDetailsByNotifyId.Type,
            pathParams,
            queryParams,
            headerParams,
            formParams,
            postBody,
            authNames,
            contentTypes,
            accepts,
            returnType,
            null
        );
    }


    getCreedRecommendationByIdUsingGET(body) {
        return this.getCreedRecommendationByIdUsingGETWithHttpInfo(body).then(function (
            response_and_data
        ) {
            return response_and_data.data;
        });
    }

    getCreedRecommendationByIdUsingGETWithHttpInfo(id) {

        let postBody = {};

        let pathParams = {
            id: id
        };
        let queryParams = {};
        let headerParams = {};
        let formParams = {};

        let authNames = ["password_grant"];
        let contentTypes = [];
        let accepts = ["*/*"];
        let returnType = [RecommendationList];
        return this.apiCreedRequest.callApi(
            this.endPoints.creedRequest.CreedRecommendation.URI,
            this.endPoints.creedRequest.CreedRecommendation.Type,
            pathParams,
            queryParams,
            headerParams,
            formParams,
            postBody,
            authNames,
            contentTypes,
            accepts,
            returnType,
            null
        );
    }


}

import { combineReducers } from 'redux';
import alert from './alert';
import app from './app';
import client from './client';
import contractor from './contractor';
import cordova from './cordova';
import creed from './creed';
import creedRequest from './creedRequest';
import djr from './djr';
import error from './error';
import holiday from './holiday';
import leave from './leave';
import message from './message';
import mta from './mta';
import notifications from './notifications';
import progress from './progress';
import project from './project';
import roster from './roster';
import subContractor from './subContractor';
import task from './task';
import user from './user';


export default combineReducers({
  app,
  cordova,
  error,
  progress,
  user,
  notifications,
  djr,
  client,
  project,
  contractor,
  subContractor,
  leave,
  holiday,
  task,
  alert,
  creed,
  creedRequest,
  mta,
  roster,
  message
});

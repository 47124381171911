import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import HomeIcon from '@mui/icons-material/Home';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import {
  BottomNavigation,
  BottomNavigationAction,
  CssBaseline,
  Paper
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as actions from "actions";
import ClientHome from "components/client-request-creeds/Client-User-Home";
import HomeAppBar from "components/home/HomeAppBar";
import ProjectAdministartionView from 'components/profile/ProjectAdministartionView';
import RosterView from 'components/roster/RosterView';
import { prop } from "ramda";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FlatScheduler from "../scheduler/FlatScheduler";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.common.bgGray,
  },
  container: {
    position: "relative",
    padding: theme.spacing(2),
    height: "calc(100vh - 110px)",
    overflow: "auto",
  },
  bottomBox: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  total: {
    fontWeight: "bold",
  },
  showAll: {
    color: theme.palette.primary.main,
  },
  transactionList: {
    marginBottom: theme.spacing(6),
  },
}));

export default function HomeView() {
  const { LoginUserRole, selectedTab } = useSelector(prop("user"));
  const [value, setValue] = React.useState(() => {
    // Extract tab from hash fragment
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.split('?')[1]);
    return params.get('tab') || selectedTab || 'Home';
  });
  const classes = useStyles();
  const [userRole, setUserRole] = useState([]);
  const { selectedDepartmentValue } = useSelector(prop('roster'));

  const setLoginUserRole = () => {
    setUserRole(LoginUserRole);
  };

  const dispatch = useDispatch();

  useEffect(setLoginUserRole, [LoginUserRole]);

  useEffect(() => {
    // Update the hash with query parameters
    const params = new URLSearchParams(window.location.hash.split('?')[1] || '');
    params.set('tab', value);
    window.location.hash = `#/home?${params.toString()}`;
  }, [value]);

  return (
    <>
      <HomeAppBar />
      <div className={classes.container}>
        <CssBaseline />
        {LoginUserRole === "staff" ? (
          <>
            {value === "Home" && <ClientHome />}
            {value === "Schedule" && <FlatScheduler />}
          </>
        ) : (
          <>
            {value === "Home" && <ClientHome />}
              {value === "Schedule" && <FlatScheduler />}
            {value === "Project" && <ProjectAdministartionView />}
              {value === "Roster" && <RosterView
                selectedDepartmentValue={selectedDepartmentValue}
                alowEdit={true}
                showCheckColumn={false}
              />}
          </>
        )}
      </div>
      <div className={classes.bottomBox}>
        <Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
          elevation={3}
        >
          {LoginUserRole === "staff" ? (
            <BottomNavigation
              showLabels
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
                dispatch(actions.setUserProps({ selectedTab: newValue }));
              }}
            >
              <BottomNavigationAction
                label="Home"
                value={"Home"}
                icon={<HomeIcon />}
              />
              <BottomNavigationAction
                label="Schedule"
                value={"Schedule"}
                icon={<EventAvailableIcon />}
              />
            </BottomNavigation>) : (
            <BottomNavigation
              showLabels
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
                dispatch(actions.setUserProps({ selectedTab: newValue }));
              }}
            >
              <BottomNavigationAction
                label="Home"
                value={"Home"}
                icon={<HomeIcon />}
              />
              <BottomNavigationAction
                label="Schedule"
                value={"Schedule"}
                icon={<EventAvailableIcon />}
              />
              <BottomNavigationAction
                sx={userRole === "staff" || userRole === "clientuser" ? { display: "none" } : {}}
                label="Project"
                value={"Project"}
                icon={<ListAltIcon />}
              />
              <BottomNavigationAction
                sx={userRole === "staff" || userRole === "clientuser" ? { display: "none" } : {}}
                label="Roster"
                value={"Roster"}
                icon={<CalendarMonthIcon />}
              />
              <BottomNavigationAction
                sx={userRole === "staff" || userRole === "clientuser" ? { display: "none" } : {}}
                label="FAQ"
                value={"FAQ"}
                icon={<LiveHelpIcon />}
              />
            </BottomNavigation>
          )}
        </Paper>
      </div>
    </>
  );
}

const data = {
  labels: ["Free", "Assigned"],
  datasets: [
    {
      label: "# of Votes",
      data: [55, 98],
      backgroundColor: ["rgba(255, 99, 132, 0.8", "rgba(54, 162, 235, 0.8)"],
      borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
      borderWidth: 1,
    },
  ],
};

import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { Box, Container, IconButton, List, Stack, Tooltip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import * as actions from "actions";
import defaultValue from "assets/data/default-value";
import SearchBar from "components/common/SearchBar";
import _ from "lodash";
import moment from 'moment';
import { prop } from "ramda";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import storage from 'services/storage';
import { formatDateTime } from "utils/strUtils";
import { STATUS_MAP } from "./Card-Item";
import CreedRequestListItem from "./CreedRequestListItem";
import CreedRequestTable from "./CreedRequestTable";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        position: "relative",
        overflow: "auto",
    },
    ul: {
        padding: 0,
        display: "grid",
        "grid-template-columns": "repeat(auto-fit, minmax(300px, 1fr))",
        "grid-gap": "5px"
    },
    subheader: {
        backgroundColor: theme.palette.background.paper,
    },
    item: {
        border: "1px solid rgb(216,216,216)",
        borderRadius: "6px",
        marginBottom: "16px",
    },
    addItem: {
        border: "1px solid rgb(216,216,216)",
        padding: "10px",
        borderRadius: "6px",
        marginBottom: "16px",
    },
    bottomContainer: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        bottom: '0px'
    }
}));

export default function CreedRequestList({ height, onItemSelect, requestType, onItemDelete, onItemCancel, onItemRateReview, onAuditLog, onItemAvailable, onItemUnAvailable }) {
    const classes = useStyles();
    const { isTableView, LoginUserId } = useSelector(prop('user'));
    const handleEditClick = (creedRequest) => () => !!onItemSelect && onItemSelect(creedRequest);
    const handelAvailable = (creedRequest) => () => !!onItemAvailable && onItemAvailable(creedRequest);
    const handelUnAvailable = (creedRequest) => () => !!onItemUnAvailable && onItemUnAvailable(creedRequest);
    const handleDeleteClick = (creedRequest) => () => !!onItemDelete && onItemDelete(creedRequest);
    const handleCancelClick = (creedRequest) => () => !!onItemCancel && onItemCancel(creedRequest);
    const handleRateReviewClick = (creedRequest) => () => !!onItemRateReview && onItemRateReview(creedRequest);
    const handleAuditLogClick = (creedRequest) => () => !!onAuditLog && onAuditLog(creedRequest);

    const { creedRequests, selectedDateRange } = useSelector(prop('creedRequest'));
    const [filteredList, setFilteredList] = useState([]);
    const [searchText, setSearchText] = useState('');

    const dispatch = useDispatch();
    const updateAll = () => {
        const dateRangeFormValue = {
            StartDate: moment(selectedDateRange[0]).format(
                defaultValue.dateTimeFormat
            ),
            EndDate: moment(selectedDateRange[1]).format(defaultValue.dateTimeFormat),
        };
        dispatch(actions.getCreedRequests({ requestType: requestType }));
        dispatch(actions.getAlertCount());
    }

    useEffect(() => {
        const todayDate = new Date();
        const selectedStartWeek = selectedDateRange[0] ? selectedDateRange[0] : startOfWeek(todayDate);
        const selectedEndWeek = selectedDateRange[1] ? selectedDateRange[1] : endOfWeek(todayDate);
        setDateRangeValue([selectedStartWeek, selectedEndWeek]);
        // updateAll();
        dispatch(
            actions.setTaskProps({
                selectedDateRange: [selectedStartWeek, selectedEndWeek]
            })
        );

    }, []);

    useEffect(() => {
        updateAll();
    }, [selectedDateRange])

    const onSearch = (e) => {
        setSearchText(e.target.value.trim().toLowerCase());
    }

    useEffect(() => {
        let filteredList = creedRequests || [];

        if (searchText.length > 0) {
            filteredList = _.filter(filteredList, (creedRequest) => {
                const keysToSearch = [
                    creedRequest.Creed?.CreedName,
                    creedRequest.Project?.ProjectName,
                    creedRequest.RequestBy?.FirstName,
                    creedRequest.RequestBy?.Role,
                    // creedRequest.Project?.Location,
                    creedRequest.ContactName,
                    creedRequest.ContactPhoneNumber,
                    creedRequest.Comment,
                    creedRequest.Location,
                    formatDateTime(creedRequest.StartDate),
                    formatDateTime(creedRequest.EndDate),
                    STATUS_MAP[creedRequest.Status]
                ];

                return keysToSearch.some(key => key?.toLowerCase().includes(searchText.toLowerCase()));
            });
        }

        setFilteredList(filteredList.map(k => ({ ...k, showAvalButton: showAvalButton(k) })));
    }, [creedRequests, searchText]);

    const startOfWeek = (date) => {
        return moment(date).startOf("week").toDate().toISOString();
    };
    const endOfWeek = (date) => {
        return moment(date).endOf("week").toDate().toISOString();
    };
    const [dateRangeValue, setDateRangeValue] = React.useState([
        startOfWeek(new Date()),
        endOfWeek(new Date()),
    ]);
    const onDateChange = (date) => {
        setDateRangeValue([date[0], date[1]]);
    };
    function showAvalButton(creedRequest) {
        return creedRequest?.UserCreedsNotify?.find(k => k.User.Id == LoginUserId)?.Status === 'Pending'
    }
    return (
        <Container disableGutters={true} style={{ height: '100%', position: 'relative' }} maxWidth={false}>
            <Box mt={1}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Stack direction="row" spacing={2}>

                    <SearchBar placeHolder='Type to Search' onChange={onSearch} />
                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDateRangePicker
                                sx={{ width: 200 }}
                                inputFormat="MM/dd/yy"
                                startText="Start Date"
                                endText="End Date"
                                value={dateRangeValue}
                                onChange={(newValue) => {
                                    onDateChange(newValue);
                                }}
                                onClose={() => {
                                    dispatch(
                                        actions.setCreedRequestProps({
                                            selectedDateRange: dateRangeValue
                                        })
                                    );
                                }}
                                on
                                renderInput={(startProps, endProps) => (
                                    <React.Fragment>
                                        <TextField {...startProps} variant="standard" />
                                        <Box sx={{ mx: 2 }}> - </Box>
                                        <TextField {...endProps} variant="standard" />
                                    </React.Fragment>
                                )}
                            />
                        </LocalizationProvider> */}
                    </Stack>
                    <Tooltip title={isTableView ? "Switch to List View" : "Switch to Table View"}>
                        <IconButton onClick={() => {
                            let tempisTableView = !isTableView
                            // setIsTableView(tempisTableView);
                            storage.set('isTableView')(tempisTableView);
                            dispatch(actions.setUserProps({ isTableView: tempisTableView }));

                        }}>
                            {isTableView ? <ViewListIcon /> : <ViewModuleIcon />}
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            {isTableView ? (
                <CreedRequestTable style={{ height, padding: '8px 0px' }} dense={false}
                    creedRequests={filteredList}
                    onEditClick={(creedRequest) => !!onItemSelect && onItemSelect(creedRequest)}
                    onItemAvailable={(creedRequest) => !!onItemAvailable && onItemAvailable(creedRequest)}
                    onItemUnAvailable={(creedRequest) => !!onItemUnAvailable && onItemUnAvailable(creedRequest)}
                    onDeleteClick={(creedRequest) => !!onItemDelete && onItemDelete(creedRequest)}
                    onRateReviewClick={(creedRequest) => !!onItemRateReview && onItemRateReview(creedRequest)}
                    onCancelClick={(creedRequest) => !!onItemCancel && onItemCancel(creedRequest)}
                    onAuditLogClick={(creedRequest) => !!onAuditLog && onAuditLog(creedRequest)}

                />
            ) : (
                    <List className={classes.root} style={{ height }} dense={false}>
                        <ul className={classes.ul}>
                            {filteredList.map((creedRequest) => (
                                <CreedRequestListItem
                                    key={creedRequest.Id}
                                    creedRequest={creedRequest}
                                    onEditClick={handleEditClick(creedRequest)}
                                    onItemAvailable={handelAvailable(creedRequest)}
                                    onItemUnAvailable={handelUnAvailable(creedRequest)}
                                    onCancelClick={handleCancelClick(creedRequest)}
                                    onDeleteClick={handleDeleteClick(creedRequest)}
                                    onRateReviewClick={handleRateReviewClick(creedRequest)}
                                    onAuditLogClick={handleAuditLogClick(creedRequest)}
                                    itemClasses={classes.item}
                                />
                            ))}
                        </ul>
                    </List>
            )}
        </Container>
    );
}

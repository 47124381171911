import { all } from "redux-saga/effects";


import alertSaga from "./alert";
import clientSaga from "./client";
import contractorSaga from "./contractor";
import cordovaSaga from './cordova';
import creedSaga from './creed';
import creedRequest from "./creedRequest";
import djrSaga from "./djr";
import errorSaga from './error';
import holidaySaga from "./holiday";
import leaveSaga from "./leave";
import messageSaga from "./message";
import getMTAs from "./mta";
import notificationsSaga from "./notifications";
import progressSaga from './progress';
import projectSaga from "./project";
import reportSaga from './report';
import rosterSaga from "./roster";
import subContractorSaga from "./subContractor";
import taskSaga from "./task";
import userSaga from './user';
export default function* rootSaga() {
    yield all([
        cordovaSaga(),
        errorSaga(),
        progressSaga(),
        userSaga(),
        notificationsSaga(),
        djrSaga(),
        clientSaga(),
        subContractorSaga(),
        projectSaga(),
        contractorSaga(),
        taskSaga(),
        alertSaga(),
        holidaySaga(),
        leaveSaga(),
        reportSaga(),
        creedSaga(),
        creedRequest(),
        getMTAs(),
        rosterSaga(),
        messageSaga()

    ]);
}

import { Box, Container, Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import * as actions from "actions";
import BackAppBar from "components/app/BackAppBar";
import FlexBox from "components/common/FlexBox";
import TextValidator from "components/common/input/TextValidator";
import PrimaryButton from "components/common/PrimaryButton";
import { prop } from "ramda";
import React, { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import defaultValue from "assets/data/default-value";
import dropDownOption from "assets/data/dropdown-options";

import { MobileDatePicker, MobileDateTimePicker } from "@mui/lab";
import PhoneValidator from "components/common/input/PhoneValidator";
import RemoveTaskDialog from "./RemoveTaskDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.paper,
  },
  top: {
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    position: "relative",
    padding: theme.spacing(2),
  },
  bottomBox: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  fullWidth: {
    width: "100%",
  },
}));

export default function TaskDetails() {
  moment.updateLocale("en", {
    week: {
      dow: 1,
    },
  });
  moment.locale("en");
  const classes = useStyles();
  const dispatch = useDispatch();
  const Types = dropDownOption.ScheduleTypes;
  const [removeTextPrimary, setremoveTextPrimary] = useState();
  const [removeTextSecondary, setremoveTextSecondary] = useState();
  const [assignToOptions, setAssignToOptions] = useState([]);


  // user list and project list
  const getOptions = () => {
    if (LoginUserRole === 'clientuser') {
      dispatch(actions.getUsersProjects());
    } else {
      dispatch(actions.getProjects());
    }

  };
  useEffect(getOptions, []);
  const { users, LoginUserRole } = useSelector(prop("user"));
  const { projects, usersProject } = useSelector(prop("project"));

  const { taskDetails } = useSelector(prop("task"));
  const { creeds } = useSelector(prop('creed'));
  const [filteredCreedList, setfilteredCreedList] = useState([])

  // const assignToOptions = users.filter((ele) => ele.Role === "staff");

  useEffect(() => {
    setAssignToOptions(users.filter((ele) => ele.IsActive));
  }, [users])


  const {
    CreedRequest = null,
    StartDate = null,
    EndDate = null,
    StartTime = null,
    EndTime = null,
    AssignedTo = null,
    Project = null,
    isNew = false,
    Id = null,
    Type = 'Task',
    Comment = '',
    WorkScope = '',
    ContactName = '',
    ContactEmail = '',
    ContactPhoneNumber = '',
    Location = '',
    CheckInTime = null,
  } = taskDetails;

  const [form, setForm] = useState({
    AssignedTo: AssignedTo,
    StartDate: StartDate,
    EndDate: EndDate,
    StartTime: StartTime,
    EndTime: EndTime,
    Project: Project,
    Id: Id,
    Type: Types.find(ele => ele.Id === Type),
    ContactName: ContactName,
    ContactEmail: ContactEmail,
    ContactPhoneNumber: ContactPhoneNumber,
    Location: Location,
    Comment: Comment,
    WorkScope: WorkScope,
    Creed: CreedRequest?.Creed,
    CheckInTime: CheckInTime
  });

  useEffect(() => {
    if (creeds && Project) {
      let proj = Project
      if (proj) {
        setfilteredCreedList(creeds.filter(k => k.Department === proj?.Department))
      } else {
        setfilteredCreedList(creeds)
      }
    }
  }, [creeds, Project])

  const hasEmptyFields = false;

  const generateMomentDateTime = (date, time) => {
    return moment(`${date} ${time}`, "YYYY-MM-DD HH:mm");
  };
  const onAdd = () => {
    const startDate = moment(form.StartDate).format("YYYY-MM-DD");
    const endDate = isNew
      ? moment(form.EndDate).format("YYYY-MM-DD")
      : startDate;

    let body = { ...form };
    let st = generateMomentDateTime(startDate, body.StartTime);
    let et = generateMomentDateTime(endDate, body.EndTime);

    const endDateTime = moment(st).isAfter(et) ? moment(startDate + " " + body.EndTime, "YYYY-MM-DD HH:mm").add(1, 'd') : et;
    body.StartDate = moment(st).format(defaultValue.saveDateTimeFormat);
    body.EndDate = moment(endDateTime).format(defaultValue.saveDateTimeFormat);


    switch (body?.Type?.Id || "Task") {
      case 'Task':
        dispatch(actions.addTask(body));
        break;
      case 'Dayoff':
      case 'Vacation':
        dispatch(actions.addLeave(body));
        break;
      case 'Holiday':
        dispatch(actions.addHoliday(body));
        break;
      default:
      // code block
    }
  };


  const disabled = LoginUserRole === "staff";
  const updateField = (prop, value) => setForm({ ...form, [prop]: value });
  const handleChange = (prop) => (e) => {
    updateField(prop, e && e.target ? e.target.value : e);

  };
  const handleBlur = (prop) => (e) => updateField(prop, e.target.value.trim());
  const submitDisabled = hasEmptyFields;

  const [openRemoveTaskDialog, setopenRemoveTaskDialog] = React.useState(false);
  const onRemove = () => {
    switch (form?.Type?.Id) {
      case 'Task':
        setremoveTextPrimary("Are you sure you want to cancel Coverage Request?");
        // setremoveTextSecondary("The task will be permanently deleted. All entered data will be lost.")

        break;
      case 'Dayoff':
      case 'Vacation':
        setremoveTextPrimary(`Are you sure you want to remove ${form?.Type?.Id}?`);
        setremoveTextSecondary(`The ${form?.Type?.Id} will be permanently deleted. All entered data will be lost.`)

        break;
      case 'Holiday':
        setremoveTextPrimary(`Are you sure you want to remove ${form?.Type?.Id}?`);
        setremoveTextSecondary(`The ${form?.Type?.Id} will be permanently deleted. All entered data will be lost.`)

        break;
      default:
        setremoveTextPrimary(`Are you sure you want to remove ?`);
        setremoveTextSecondary(`The data will be permanently deleted. All entered data will be lost.`)

      // code block
    }
    setopenRemoveTaskDialog(true);
  };
  const OnClockInClick = () => {
    dispatch(actions.clockOn({
      Id: form.Id,
      // requestType: requestType,
      goBack: true
    }));
  }
  const onRemoveConfirm = () => {
    switch (form?.Type?.Id) {
      case 'Task':
        // dispatch(actions.deleteTaskById(form.Id));
        dispatch(actions.cancelCreedRequest({
          creedRequest: CreedRequest,
          // requestType: requestType,
          goBack: true
        }));
        break;
      case 'Dayoff':
      case 'Vacation':
        dispatch(actions.removeLeave(form.Id));
        break;
      case 'Holiday':
        dispatch(actions.removeHoliday(form.Id));
        break;
      default:
      // code block
    }

  };
  const closeDialog = () => {
    setopenRemoveTaskDialog(false);
  };

  const isTask = () => form?.Type?.Id === 'Task';
  const isHoliday = () => form?.Type?.Id === 'Holiday';




  return (
    <>
      <BackAppBar />
      <Container className={classes.root}>
        <FlexBox className={classes.top}>
          <Box flexGrow={1} pl={2}>
            <Typography variant="h5">
              <strong data-testid="label-user-administration">
                {!isNew ? <>Edit Task</> : <>Add a task</>}
              </strong>
            </Typography>
          </Box>
        </FlexBox>

        <Container className={classes.container}>
          <ValidatorForm instantValidate={true} autoComplete="off">
            <Grid container spacing={2} className={classes.form}>
              <Grid item xs={12} sm={6} className={classes.column}>
                <Autocomplete
                  id="vaction-type-select"
                  options={Types}
                  // disabled={!isNew}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Type"
                      variant="standard"
                      margin="normal"
                      fullWidth
                      disabled={true}
                    />
                  )}

                  disabled={true}
                  getOptionLabel={(option) => option.Name}
                  value={form?.Type}
                  onChange={(_event, newValue) => {
                    updateField("Type", newValue);
                  }}
                />
              </Grid>
              {!isHoliday() ? (
                <Grid item xs={12} sm={6} className={classes.column}>

                  <Autocomplete
                    id="user-type-select"
                    options={assignToOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Assigned To"
                        variant="standard"
                        margin="normal"
                        fullWidth
                      />
                    )}
                    disabled={true}
                    // disabled={!isNew ? (Djr || InspectionPDF.lenght > 0) : false}
                    getOptionLabel={(option) => option.Name}
                    value={form?.AssignedTo}
                    onChange={(_event, newValue) => {
                      updateField("AssignedTo", newValue);
                    }}
                  /> </Grid>) : ''}

              {isTask() ?
                (<>
                  <Grid item xs={12} sm={6} className={classes.column}>
                    <Autocomplete
                      id="project-type-select"
                      options={LoginUserRole === 'clientuser' ? usersProject : projects}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Project Name"
                          variant="standard"
                          margin="normal"
                          fullWidth
                        />
                      )}
                      disabled={true}
                      // getOptionLabel={(option) => option.ProjectName}
                      getOptionLabel={(option) =>
                        `${option.ProjectNo} (${option.ProjectName})`
                      }
                      value={form?.Project}
                      onChange={(_event, newValue) => {
                        updateField("Project", newValue);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.column}>
                    <Autocomplete
                      id="creed"
                      name="Credentials"
                      options={filteredCreedList?.filter(k => k.ShowToClients)}
                      sx={{ width: "100%" }}
                      disabled={disabled}
                      renderInput={(params) => (
                        <TextField
                          disabled={disabled}
                          {...params}
                          label="Job Position"
                          variant="standard"
                          margin="normal"
                          fullWidth
                        />
                      )}
                      getOptionLabel={(option) => option.CreedName}

                      value={form?.Creed}
                      onChange={(_event, newValue) => {
                        updateField("Creed", newValue);
                      }}
                    />
                  </Grid>               
                  {/* <Grid item xs={12} sm={6} className={classes.column}>
                    <Autocomplete
                      id="project-type-select"
                      options={addressOption}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Project Address"
                          variant="standard"
                          margin="normal"
                          fullWidth
                        />
                      )}
                      // getOptionLabel={(option) => option.ProjectName}
                      getOptionLabel={(option) =>
                        `${option.Location}`
                      }
                      value={form?.ProjectAddress}
                      onChange={(_event, newValue) => {
                        updateField("ProjectAddress", newValue);
                      }}
                    />
                  </Grid> */}

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid item xs={12} sm={6} className={classes.column}>
                      <MobileDatePicker
                        name="StartDate"
                        label="Start Date"
                        value={form?.StartDate}
                        onChange={handleChange("StartDate")}
                        // disabled={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            margin="normal"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    {isNew && (
                      <Grid item xs={12} sm={6} className={classes.column}>
                        <MobileDatePicker
                          name="EndDate"
                          label="End Date"
                          value={form?.EndDate}
                          onChange={handleChange("EndDate")}
                          // disabled={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              margin="normal"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6} className={classes.column}>
                      <TextField
                        id="start-time"
                        label="Start Time"
                        type="time"
                        fullWidth
                        variant="standard"
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        defaultValue={form?.StartTime}
                        onChange={handleChange("StartTime")}
                        // disabled={true}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.column}>
                      <TextField
                        id="end-time"
                        label="End Time"
                        type="time"
                        fullWidth
                        variant="standard"
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        defaultValue={form?.EndTime}
                        onChange={handleChange("EndTime")}
                        // disabled={true}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      /> </Grid>

                  </LocalizationProvider>
                  <Grid item xs={12} sm={12} md={12} className={classes.column}></Grid>
                  <Grid item xs={12} sm={6} md={6} className={classes.column}>
                    <TextValidator
                      autoComplete='new-password'
                      name="ContactName"
                      label="Site Contact Name"
                      value={form?.ContactName}
                      margin="normal"
                      fullWidth
                      // disabled={disabled}
                      // disabled={true}
                      data-testid="input-user-name"
                      onChange={handleChange("ContactName")}

                    />
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.column}>
                    <TextValidator
                      autoComplete='new-password'
                      name="ContactEmail"
                      label="Site Contact Email"
                      value={form?.ContactEmail}
                      margin="normal"
                      fullWidth
                      // disabled={disabled}
                      // disabled={true}
                      onChange={handleChange("ContactEmail")}

                      validators={["isEmail"]}
                      errorMessages={["Email is not valid"]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.column}>
                    <PhoneValidator
                      defaultCountry="us"
                      disableAreaCodes
                      countryCodeEditable={false}
                      label="Site Contact Phone number"
                      value={form?.ContactPhoneNumber}
                      onChange={handleChange("ContactPhoneNumber")}
                      fullWidth
                      // disabled={disabled}
                      // disabled={true}
                      margin="normal"
                      validators={["isPhone"]}
                      errorMessages={[
                        "The Mobile number is not valid",
                      ]}
                      // InputProps={inputProps}
                      data-testid='input-MobileNo-number'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} className={classes.column}>
                    <TextValidator
                      autoComplete='new-password'
                      name="Location"
                      label="Site Location"
                      value={form?.Location}
                      margin="normal"
                      fullWidth
                      // disabled={disabled}
                      // disabled={true}
                      data-testid="input-user-name"
                      onChange={handleChange("Location")}

                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} className={classes.column}>
                    <TextValidator
                      autoComplete='new-password'
                      name="Comment"
                      label="Comments"
                      value={form?.Comment}
                      margin="normal"
                      fullWidth
                      multiline
                      rows={2}
                      // disabled={true}
                      // disabled={disabled}
                      data-testid="input-Comment"
                      onChange={handleChange("Comment")}
                      onBlur={handleBlur("Comment")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} className={classes.column}>
                    <TextValidator
                      autoComplete='new-password'
                      name="WorkScope"
                      label="Work Scope"
                      value={form?.WorkScope}
                      margin="normal"
                      fullWidth
                      multiline
                      rows={2}
                      // disabled={true}
                      // disabled={disabled}
                      data-testid="input-Comment"
                      onChange={handleChange("WorkScope")}
                      onBlur={handleBlur("WorkScope")}
                    />
                  </Grid>
                  <>
                    <Grid item xs={12} sm={6} className={classes.column}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDateTimePicker
                          label="Check In Time"
                          value={form?.CheckInTime}
                          // onChange={handleChange("EndDate")}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              margin="normal"
                              fullWidth
                            />
                          )}
                          disabled={true}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </>
                </>
                ) : (<>
                  <Grid item xs={12} sm={6} className={classes.column}>

                    <TextValidator
                      autoComplete='new-password'
                      name="Name"
                      label="Reason"
                      value={form?.Reason}
                      margin="normal"
                      disabled={true}
                      fullWidth
                      data-testid="input-user-name"
                      onChange={handleChange("Reason")}
                      onBlur={handleBlur("Reason")}
                    />
                  </Grid>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid item xs={12} sm={6} className={classes.column}>

                      <MobileDateTimePicker
                        label="Start Date"
                        value={form?.StartDate}
                        onChange={handleChange("StartDate")}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            margin="normal"
                            fullWidth
                          />
                        )}
                        disabled={true}
                      />
                    </Grid>
                    {isNew && !isHoliday() ? (
                      <>
                        <Grid item xs={12} sm={6} className={classes.column}>

                          <MobileDateTimePicker
                            label="End Date"
                            value={form?.EndDate}
                            onChange={handleChange("EndDate")}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                margin="normal"
                                fullWidth
                              />
                            )}
                            disabled={true}
                          />
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}

                  </LocalizationProvider>

                </>)}
            </Grid>
          </ValidatorForm>
        </Container>
        {(!isNew && LoginUserRole == "staff") && form?.CheckInTime == null ? (<>

          <>
            <PrimaryButton
              onClick={OnClockInClick}
              data-testid="btn-remove-task"
              color="error"
            >
              Clock In
            </PrimaryButton>
          </>
        </>

        ) : ""

        }
        {!isNew && LoginUserRole !== "staff" ? (
          <>
            <PrimaryButton
              onClick={onRemove}
              disabled={submitDisabled}
              data-testid="btn-remove-task"
              color="error"
            >
              Cancel
            </PrimaryButton>
          </>
        ) : (
          <></>
        )}
        {LoginUserRole === "admin" ? (
          <>
            <FlexBox justifyContent="center">
              {!isNew ? (
                <>
                  <PrimaryButton
                    onClick={onAdd}
                    // disabled={submitDisabled}
                    // disabled={true}
                    data-testid="btn-add-task"
                  >
                    Save
                  </PrimaryButton>
                </>
              ) : (
                <PrimaryButton
                  onClick={onAdd}
                  data-testid="btn-add-task"
                    disabled={true}
                    // disabled={submitDisabled}
                >
                  Add New
                </PrimaryButton>
              )}
            </FlexBox>
          </>
        ) : (
          <></>
        )}
        <RemoveTaskDialog
          open={openRemoveTaskDialog}
          onClose={closeDialog}
          onRemoveConfirm={onRemoveConfirm}
          removeTextPrimary={removeTextPrimary}
          removeTextSecondary={removeTextSecondary}
        ></RemoveTaskDialog>
      </Container>
    </>
  );
}

// import { all, put, takeLatest } from "redux-saga/effects";
import {
  all,
  put,
  takeLatest
} from "redux-saga/effects";
import path from "routesPath";
import {
  endProgress,
  failProgress,
  startProgress,
} from "sagas/common";
import Api from "services/api";
import * as actions from "../actions";
import history from "../history";


var moment = require('moment-timezone');
function* getTask(action) {
  const progress = yield startProgress();
  try {

    const body = action.payload;
    const tasks = yield Api.task.getTasksUsingPost(body);
    yield put(actions.setTaskProps({ tasks: tasks }));
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}
function* onClockON(action) {
  try {
    const id = action.payload.Id;
    const creedRequest = yield Api.task.onClockON(id);

    yield put(actions.showSuccessMessage("Clock ON successful!"));
    if (action.payload.goBack) {
      history.goBack()
    }
  } catch (error) {
    console.error(error);
  }
}

function* getTaskById(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const tasks = yield Api.task.getTaskByIdUsingPOST(body);
    tasks["User"] = tasks.AssignedTo;
    tasks["StartTime"] = moment(tasks.StartDate).format('HH:mm'); 
    tasks["EndTime"] = moment(tasks.EndDate).format('HH:mm');
    tasks["AssignedName"] =
      tasks.AssignedTo.FirstName + tasks.AssignedTo.LastName;
    // yield put(actions.showTaskDetails({ tasks }));
    yield put(actions.setTaskProps({ taskDetails: tasks }));
    history.push(path.task.taskDetail);
    // console.log(tasks);
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}
function* deleteTaskById(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const param = {
      "Id": body
    }
    const tasks = yield Api.task.deleteTaskByIdUsingPOST(param);
    // console.log(tasks);
    history.goBack();
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

function* addTask(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    if (body.Id) {
      yield Api.task.updateTask(body);
    } else {
      yield Api.task.addTask(body);
    }
    history.goBack();
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

function* showTaskDetails(action) {
  const progress = yield startProgress();
  try {
    const { task } = action.payload;
    yield put(actions.setTaskProps({ taskDetails: task }));
    history.push(path.task.taskDetail);
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}
function* freezColumn(action) {
  yield put(actions.setTaskProps({ freezColumn: action.payload.val }));
}

function* fieldStaffChecked(action){
  yield put(actions.setTaskProps({ fieldStaffChecked: action.payload.val }));
}

export default function* saga() {
  yield all([
    takeLatest(actions.GET_TASK, getTask),
    takeLatest(actions.ADD_TASK, addTask),
    takeLatest(actions.TASK_DETAILS, showTaskDetails),
    takeLatest(actions.GET_BY_ID_TASK, getTaskById),
    takeLatest(actions.DELETE_BY_ID_TASK, deleteTaskById),
    takeLatest(actions.FREEZ_COLUMN, freezColumn),
    takeLatest(actions.FIELD_STAFF_CHECKED, fieldStaffChecked),
    takeLatest(actions.CLOCK_ON, onClockON),
  ]);
}
